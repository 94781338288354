"use strict";

jQuery(function ($) {
  var row_items = $('.ajax_row'),
    ajax_row_holder = $('.ajax_row_holder'),
    posts_per_page = '',
    posts_type = '',
    pagination_type = '',
    taxonomy_type = '';
  function posts_per_page_fn() {
    posts_per_page = row_items.attr('data-posts-per-page');
  }
  function posts_type_fn() {
    posts_type = row_items.attr('data-posts-type');
  }
  function pagination_type_fn() {
    pagination_type = row_items.attr('data-pagination-type');
  }
  function taxonomy_type_fn() {
    taxonomy_type = row_items.attr('data-taxonomy');
  }
  $(document).on('click', '#pagination_holder .load_page', function (e) {
    e.preventDefault();
    posts_per_page_fn();
    posts_type_fn();
    pagination_type_fn();
    taxonomy_type_fn();
    loadmore_params.current_page = $(this).data('slug');
    var category = $('.js-category-filter.active').attr('data-slug'),
      search = $('#all-post-search').val(),
      order = $('#js-post-order').val(),
      this_load_more = $(this).parents('#pagination_holder'),
      clearRow = true;
    if ($(this).hasClass('load_more')) {
      clearRow = false;
    }
    var data = {
      'action': 'loadmore',
      'query': loadmore_params.posts,
      'page': loadmore_params.current_page,
      'posts_per_page': posts_per_page,
      'post_type': posts_type,
      'pagination_type': pagination_type,
      'category': category,
      'taxonomy_type': taxonomy_type,
      'search': search,
      'order': order
    };
    $.ajax({
      url: loadmore_params.ajaxurl,
      data: data,
      type: 'POST',
      beforeSend: function beforeSend(xhr) {
        ajax_row_holder.css("opacity", "0.5");
      },
      success: function success(data) {
        if (data) {
          this_load_more.remove();
          if (clearRow === true) {
            row_items.empty();
            $('html,body').stop().animate({
              scrollTop: row_items.offset().top
            }, 300);
          }
          row_items.append(data.html);
          ajax_row_holder.append(data.pagination);
          ajax_row_holder.css("opacity", "1");
        }
      }
    }).done(function () {
      $(document).trigger('AjaxPaginationDone');
    });
  });
  $('.js-category-filter').on('click', function () {
    if (!$(this).hasClass('active')) {
      $('.js-category-filter').removeClass('active');
      $(this).addClass('active');
    } else {
      $('.js-category-filter').removeClass('active');
    }
  });
  $('#js-post-order').on('change', function () {
    $('#all_posts_filter').trigger('submit');
  });
  $('#all_posts_filter').on('submit', function (e) {
    e.preventDefault();
    loadmore_params.current_page = 1;
    var category = $('.js-category-filter.active').attr('data-slug'),
      search = $('#all-post-search').val(),
      order = $('#js-post-order').val(),
      this_load_more = $('#pagination_holder');
    posts_per_page_fn();
    posts_type_fn();
    pagination_type_fn();
    taxonomy_type_fn();
    var data = {
      'action': 'loadmore',
      'query': loadmore_params.posts,
      'page': loadmore_params.current_page,
      'posts_per_page': posts_per_page,
      'post_type': posts_type,
      'pagination_type': pagination_type,
      'category': category,
      'taxonomy_type': taxonomy_type,
      'search': search,
      'order': order
    };
    $.ajax({
      url: loadmore_params.ajaxurl,
      data: data,
      type: 'POST',
      beforeSend: function beforeSend(xhr) {
        ajax_row_holder.css("opacity", "0.5");
      },
      success: function success(data) {
        if (data) {
          this_load_more.remove();
          row_items.empty();
          row_items.append(data.html);
          ajax_row_holder.append(data.pagination);
          ajax_row_holder.css("opacity", "1");
        }
      }
    });
  });
});